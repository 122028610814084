<template>
  <CInputRadioGroup
    @update:checked="setValue"
    :options="options"
    :checked="checkedProp"
    inline
    class="input-radio-row"
  />
</template>

<script>
export default {
  name: "FilterIsCumulative",
  data() {
    return {
      lang: null,
      messages: null,
      checkedProp: "0",
    };
  },
  computed: {
    options() {
      return [
        { value: "0", label: this.messages.filters.isNoCumulative },
        { value: "1", label: this.messages.filters.isCumulative },
      ];
    },
  },
  methods: {
    setValue(value) {
      console.log(value);
      this.$store.dispatch("saveFilterIsCumulativeValue", {
        value: value,
      });
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.$store.dispatch("saveFilterIsCumulativeValue", {
      value: "0",
    });
  },
};
</script>

<style>
.input-radio-row {
  height: 2rem;
}
</style>
